"use strict";
/**
 * @module Races
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateRaceRatingById = exports.getActiveRace = exports.getRaceList = exports.getRaceById = exports.updateRaceById = exports.deleteRaceById = exports.createRace = void 0;
var createRace_1 = require("./createRace");
Object.defineProperty(exports, "createRace", { enumerable: true, get: function () { return createRace_1.createRace; } });
var deleteRaceById_1 = require("./deleteRaceById");
Object.defineProperty(exports, "deleteRaceById", { enumerable: true, get: function () { return deleteRaceById_1.deleteRaceById; } });
var updateRaceById_1 = require("./updateRaceById");
Object.defineProperty(exports, "updateRaceById", { enumerable: true, get: function () { return updateRaceById_1.updateRaceById; } });
var getRaceById_1 = require("./getRaceById");
Object.defineProperty(exports, "getRaceById", { enumerable: true, get: function () { return getRaceById_1.getRaceById; } });
var getRaceList_1 = require("./getRaceList");
Object.defineProperty(exports, "getRaceList", { enumerable: true, get: function () { return getRaceList_1.getRaceList; } });
var getActiveRace_1 = require("./getActiveRace");
Object.defineProperty(exports, "getActiveRace", { enumerable: true, get: function () { return getActiveRace_1.getActiveRace; } });
var updateRaceRatingById_1 = require("./updateRaceRatingById");
Object.defineProperty(exports, "updateRaceRatingById", { enumerable: true, get: function () { return updateRaceRatingById_1.updateRaceRatingById; } });
